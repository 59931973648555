import React from "react";
import {
	Box,
	Grid,
	IconButton,
	Tooltip
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	PlayArrowRounded as PlayArrowRoundedIcon,
	DeleteRounded as DeleteIcon
} from "@mui/icons-material";
import {

} from "../DialogImagesVideos"
import Dropzone from "react-dropzone"
import PropTypes from "prop-types";
import VideoPreview from "../VideoPreview";
import clsx from "clsx";

const FileUploader = (props) => {
	const {
		name,
		value,
		accept,
		multiple,
		disabledCreate,
		isInitOpenPopUp,
		onChange,
		onDeleteWorkFile,
	} = props;
	const classes = useStyles();
	const refDropzone = React.createRef();

	React.useEffect(() => {
		checkInitOpenPopUp();
	}, []);

	const checkInitOpenPopUp = () => {
		if (isInitOpenPopUp) {
			refDropzone.current?.open();
		}
	}

	const handleOnDrop = (data) => {
		data = data.map((file) => {
			return {
				isNew: true,
				file: file
			}
		})

		if (!multiple) {
			onChange({
				target: {
					name,
					value: data?.[0]
				}
			});
		}
		if (multiple) {
			onChange({
				target: {
					name,
					value: [...value, ...data]
				}
			});
		}
	}
	const handleDeleteFile = (index) => {
		let newValue = _selectedFiles();
		newValue.splice(index, 1);


		if (!multiple) {
			onChange({
				target: {
					name,
					value: newValue?.[0] || undefined
				}
			});
		}
		if (multiple) {
			onChange({
				target: {
					name,
					value: newValue || []
				}
			});
		}
	}
	const handleOpenFile = (event, params) => {
		event.stopPropagation();

		if (typeof params === "string") {
			const link = params;
			window.open(link, "_blank")

			return
		}
		if (typeof params === "object") {
			const link = URL.createObjectURL(params);
			window.open(link, "_blank")

			return
		}
	}

	const handleOpenImage = (url, isNew) => {
		if (isNew) return null
		window.open(url, '_blank');
	}

	const _placeholder = () => {
		if (!!multiple) {
			return "Выберите или ператащите файлы"
		}

		return "Выберите или ператащите файл"
	}
	const _selectedFiles = () => {
		let data = [];
		if (multiple) {
			data = value;
		}
		if (!multiple) {
			data = [value];
		}

		return data.filter((t) => !!t)
	}

	const _deleteWorkFile = async (fileId, index) => {
		onDeleteWorkFile(fileId, () => handleDeleteFile(index));
	};

	return (
		<Dropzone
			ref={refDropzone}
			maxFiles={Boolean(multiple) ? null : 1}
			accept={accept}
			onDrop={handleOnDrop}
		>
			{({getRootProps, getInputProps, acceptedFiles}) => {

				return (
					<>

						{Boolean(!disabledCreate) && (
							<Box className={clsx([classes.root, 'root-dropzone'])} {...getRootProps()}>
								<input
									{...getInputProps()}
									multiple={multiple}
								/>
								<div className={classes.message}>{ _placeholder() }</div>
							</Box>
						)}

						{Boolean(_selectedFiles().length > 0) && (
							<Box className={classes.selectedFiles}>
								<div className={classes.selectedFilesLabel}>Выбранные файлы</div>
								<Grid container spacing={2}>
									{_selectedFiles().map((file, index) => {
										const type = typeof (file?.file || file);
										const fileType = (file?.file || file)?.type || (file?.file || file)?.mimetype;
										let fileUri = file?.fileUrl;
										console.log('file?.type: ', file, fileType, type);
										if (fileType !== 'application/pdf' && type === "object" && file?.isNew) {
											fileUri = URL.createObjectURL(file?.file)
										}
										if (type !== "object") {
											fileUri = file;
										}
										const isVideo = Boolean((fileType || fileType || file?.mimetype || "").includes("video"));
										const isNew = file.isNew;
										return (
											<Grid item xs={6} lg="auto">
												<Tooltip title={isNew ? "Удалить" : file?.file?.name} arrow>
													<Box
														className={classes.selectedFile}
														key={`selected-file-${ index }`}
														onClick={isNew ? handleDeleteFile.bind(this, index) : null}
													>
														{Boolean(!isVideo) && (
															<img
																src={ fileUri }
																onClick={handleOpenImage.bind(this, fileUri, isNew)}
															/>
														)}
														{Boolean(isVideo) && (
															<>
																<VideoPreview file={file}/>
																<div className="play" onClick={(event) => handleOpenFile(event, file.file)}>
																	<PlayArrowRoundedIcon color="primary" sx={{fontSize: 40}}/>
																</div>
															</>
														)}
														{!isNew && (
															<Tooltip title="Удалить файл" arrow>
																<IconButton
																	color="error"

																	onClick={_deleteWorkFile.bind(this, file.fileId, index)}
																>
																	<DeleteIcon/>
																</IconButton>
															</Tooltip>
														)}
													</Box>
												</Tooltip>
											</Grid>
										)
									})}
								</Grid>
							</Box>
						)}

					</>
				)
			}}
		</Dropzone>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		height: 160,
		backgroundColor: "#f3f5f9",
		borderRadius: 4,
		position: "relative",
		cursor: "pointer"
	},
	message: {
		position: "absolute",
		left: "50%",
		top: "50%",
		transform: "translate(-50%, -50%)",

		fontSize: 20,
		lineHeight: "32px",
		color: "#647083",
		opacity: 0.8,
		whiteSpace: "nowrap"
	},

	selectedFiles: {
		marginTop: 12
	},
	selectedFilesLabel: {
		fontSize: 16,
		lineHeight: "24px",
		color: "#152435",
		fontWeight: "500",

		marginBottom: 8
	},

	selectedFile: {
		width: 120,
		height: 120,
		backgroundColor: "white",
		border: "1px solid #ff3f3f",
		borderRadius: 4,
		overflow: "hidden",
		boxSizing: "border-box",
		position: "relative",

		"& img": {
			width: "100%",
			height: "100%",
			objectFit: "contain",
		},
		"& video": {
			width: "100%",
			height: "100%",
			objectFit: "cover",
		},
		"& .play": {
			width: 40,
			height: 40,
			position: "absolute",
			left: "50%",
			top: "50%",
			transform: "translate(-50%,-50%)"
		},

		"& > button": {
			width: 25,
			height: 25,
			background: "#FF3F3F",

			position: "absolute",
			right: 0,
			top: 0,

			"&:hover": {
				background: "#FF3F3F",
			},

			"& > svg": {
				width: 15,
				height: "auto",
				fill: "white",
			}
		},

		"@media(max-width: 1199px)": {
			width: "auto"
		}
	}
}));

FileUploader.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.any.isRequired,

	accept: PropTypes.string,
	multiple: PropTypes.bool,

	onChange: PropTypes.func.isRequired,
	onDeleteWorkFile: PropTypes.func,
};

export default React.memo(FileUploader)
